import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import PanelLayout from './components/Layout/Panel/Panel';
import Dashboard from './views/Panel/General/Dashboard';
import AuthorizationLayout from './components/Layout/Authorization';
import SignInForm from './views/Authorization/SignIn';
import SignUpForm from './views/Authorization/SignUp';
import ResetPassword from './views/Authorization/ResetPassword';
import ChangeEmail from './views/User/ChangeEmail';
import UsersTable from './views/Panel/Access/Users/Table';
import UsersForm from './views/Panel/Access/Users/Form';
import RolesTable from './views/Panel/Access/Roles/Table';
import RolesForm from './views/Panel/Access/Roles/Form';
import RouterErrorBoundary from './components/Feedback/RouterErrorBoundary';
import SignOut from './views/Authorization/SignOut';
import ChangePassword from './views/User/ChangePassword';
import UserPanel from './components/Layout/UserPanel/UserPanel';
import FrontLayout from './components/Layout/Front/Layout';
import Index from './views/Index/Index';
import UserIndex from './views/User/UserIndex';
import Confirm from './views/User/Confirm';
import NotificationPreferences from './views/User/NotificationPreferences';
import ChangePhoneNumber from './views/User/ChangePhoneNumber';
import NotificationBroadcastersTable from './views/Panel/Notifications/Broadcasters/Table';
import NotificationBroadcastersForm from './views/Panel/Notifications/Broadcasters/Form';
import NotificationTypesTable from './views/Panel/Notifications/Types/Table';
import NotificationTypesForm from './views/Panel/Notifications/Types/Form';
import HelpPagesTable from './views/Panel/Cms/Help/Pages/Table';
import HelpPagesForm from './views/Panel/Cms/Help/Pages/Form';
import HelpCategoriesTable from './views/Panel/Cms/Help/Categories/Table';
import HelpCategoriesForm from './views/Panel/Cms/Help/Categories/Form';
import PagesTable from './views/Panel/Cms/Pages/Table';
import PagesForm from './views/Panel/Cms/Pages/Form';
import NewsfeedPostsTable from './views/Panel/Cms/Newsfeed/Posts/Table';
import NewsfeedPostsForm from './views/Panel/Cms/Newsfeed/Posts/Form';
import NewsfeedCategoriesTable from './views/Panel/Cms/Newsfeed/Categories/Table';
import NewsfeedCategoriesForm from './views/Panel/Cms/Newsfeed/Categories/Form';
import GalleryGalleriesTable from './views/Panel/Cms/Galleries/Galleries/Table';
import GalleryGalleriesForm from './views/Panel/Cms/Galleries/Galleries/Form';
import GalleryCategoriesTable from './views/Panel/Cms/Galleries/Categories/Table';
import GalleryCategoriesForm from './views/Panel/Cms/Galleries/Categories/Form';
import Support from './views/Panel/General/Support';
import Page from './views/Cms/Page';
import Newsfeed from './views/Cms/Newsfeed';
import NewsfeedPost from './views/Cms/NewsfeedPost';
import HelpIndex from './views/Cms/HelpIndex';
import HelpPage from './views/Cms/HelpPage';
import GalleriesIndex from './views/Cms/GalleriesIndex';
import Gallery from './views/Cms/Gallery';
import BookTraining from './views/Stable/BookTraining/BookTraining';
import Contact from './views/Crm/Contact';
import { HorsesView } from './views/Stable/Horses';
import { InstructorsView } from './views/Stable/Instructors';
import { TrainingTypesView } from './views/Stable/TrainingTypes';
import Settings from './views/Panel/General/Settings';
import HorsesForm from './views/Panel/Stable/Horses/Form';
import HorsesTable from './views/Panel/Stable/Horses/Table';
import TrainingTypesTable from './views/Panel/Stable/TrainingTypes/Table';
import TrainingTypesForm from './views/Panel/Stable/TrainingTypes/Form';
import PlacesTable from './views/Panel/Stable/Places/Table';
import PlacesForm from './views/Panel/Stable/Places/Form';
import InstructorsTable from './views/Panel/Stable/Instructors/Table';
import InstructorsForm from './views/Panel/Stable/Instructors/Form';
import RidersTable from './views/Panel/Stable/Riders/Table';
import RidersForm from './views/Panel/Stable/Riders/Form';
import TrainingsTable from './views/Panel/Booking/Trainings/Table';
import TrainingsForm from './views/Panel/Booking/Trainings/Form';
import Trainings from './views/User/Trainings';
import Training from './views/User/Training';
import HorseTasksTable from './views/Panel/Stable/HorseTasks/Table';
import HorseTasksForm from './views/Panel/Stable/HorseTasks/Form';
import GeneralTasksTable from './views/Panel/Stable/GeneralTasks/Table';
import GeneralTasksForm from './views/Panel/Stable/GeneralTasks/Form';
import WorkersTable from './views/Panel/Stable/Workers/Table';
import WorkersForm from './views/Panel/Stable/Workers/Form';
import ReturnView from './views/Payments/ReturnView';
import PaymentMethodsTable from './views/Panel/Accountancy/PaymentMethods/Table';
import PaymentMethodsForm from './views/Panel/Accountancy/PaymentMethods/Form';
import InvoiceNumberingsTable from './views/Panel/Accountancy/InvoiceNumberings/Table';
import InvoiceNumberingsForm from './views/Panel/Accountancy/InvoiceNumberings/Form';
import CurrenciesTable from './views/Panel/Accountancy/Currencies/Table';
import CurrenciesForm from './views/Panel/Accountancy/Currencies/Form';
import SellersTable from './views/Panel/Accountancy/Sides/Sellers/Table';
import SellersForm from './views/Panel/Accountancy/Sides/Sellers/Form';
import BuyersTable from './views/Panel/Accountancy/Sides/Buyers/Table';
import BuyersForm from './views/Panel/Accountancy/Sides/Buyers/Form';
import PaymentRequestsTable from './views/Panel/Accountancy/PaymentRequests/Table';
import PaymentRequestsForm from './views/Panel/Accountancy/PaymentRequests/Form';
import InvoicesTable from './views/Panel/Accountancy/Invoices/Table';
import InvoicesForm from './views/Panel/Accountancy/Invoices/Form';
import CouponsTable from './views/Panel/Booking/Coupons/Table';
import CouponsForm from './views/Panel/Booking/Coupons/Form';
import UserCouponsTable from './views/Panel/Booking/UserCoupons/Table';
import UserCouponsForm from './views/Panel/Booking/UserCoupons/Form';
import AgendaView from './views/Panel/General/Agenda';
import WizardIndex from './views/Wizard/Index';
import PersonalizationView from './views/Panel/General/Personalization';
import Error402 from './views/Errors/Error402';
import { PriceListView } from './views/Stable/PriceList';
import CouponPayment from './views/Stable/CouponPayment';


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RouterErrorBoundary />}>

      <Route path="/:locale/wizard" element={<WizardIndex />} />
      <Route path="/:locale/402" element={<Error402 />} />

      <Route element={<FrontLayout />}>
        <Route index path="/:locale?" element={<Index />} />
        <Route path="/:locale/p/:url" element={<Page />} />
        <Route path="/:locale/n" element={<Newsfeed />} />
        <Route path="/:locale/n/c/:url" element={<Newsfeed />} />
        <Route path="/:locale/n/p/:url" element={<NewsfeedPost />} />
        <Route path="/:locale/h" element={<HelpIndex />} />
        <Route path="/:locale/h/c/:url" element={<HelpIndex />} />
        <Route path="/:locale/h/p/:url" element={<HelpPage />} />
        <Route path="/:locale/g" element={<GalleriesIndex />} />
        <Route path="/:locale/g/c/:url" element={<GalleriesIndex />} />
        <Route path="/:locale/g/g/:url" element={<Gallery />} />

        <Route path="/:locale/stable/book-training/:typeId?" element={<BookTraining />} />
        <Route path="/:locale/stable/horses" element={<HorsesView />} />
        <Route path="/:locale/stable/instructors" element={<InstructorsView />} />
        <Route path="/:locale/stable/training-types" element={<TrainingTypesView />} />
        <Route path="/:locale/stable/price-list" element={<PriceListView />} />
        <Route path="/:locale/stable/coupon-payment/:couponId" element={<CouponPayment />} />

        <Route path="/:locale/contact" element={<Contact />} />

        <Route path="/:locale/download" element={<Index />} />

        <Route path="/:locale?/payment/return" element={<ReturnView />} />
        <Route path="/payment/return" element={<ReturnView />} />
      </Route>
      <Route path="/:locale/auth/" element={<AuthorizationLayout />}>
        <Route path="sign-in" element={<SignInForm />} />
        <Route path="sign-up" element={<SignUpForm />} />
        <Route path="sign-out" element={<SignOut />} />
        <Route path="reset-password/:email?/:token?" element={<ResetPassword />} />
      </Route>
      <Route path="/:locale/user/" element={<UserPanel />}>
        <Route path="" element={<UserIndex />} />
        <Route path="trainings" element={<Trainings />} />
        <Route path="trainings/:id" element={<Training />} />
        <Route path="change-email/:email?/:token?" element={<ChangeEmail />} />
        <Route path="change-password/" element={<ChangePassword />} />
        <Route path="change-phone-number/" element={<ChangePhoneNumber />} />
        <Route path="notification-preferences/" element={<NotificationPreferences />} />
        <Route path="confirm/:token?" element={<Confirm />} />
      </Route>
      <Route path="/:locale/panel/" element={<PanelLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="support" element={<Support />} />
        <Route path="settings" element={<Settings />} />
        <Route path="agenda" element={<AgendaView />} />
        <Route path="personalization" element={<PersonalizationView />} />

        <Route path="users" element={<UsersTable />} />
        <Route path="users/:id" element={<UsersForm />} />
        <Route path="roles" element={<RolesTable />} />
        <Route path="roles/:id" element={<RolesForm />} />

        <Route path="notifications/broadcasters" element={<NotificationBroadcastersTable />} />
        <Route path="notifications/broadcasters/:id" element={<NotificationBroadcastersForm />} />
        <Route path="notifications/types" element={<NotificationTypesTable />} />
        <Route path="notifications/types/:id" element={<NotificationTypesForm />} />

        <Route path="content/help" element={<HelpPagesTable />} />
        <Route path="content/help/pages" element={<HelpPagesTable />} />
        <Route path="content/help/pages/:id" element={<HelpPagesForm />} />
        <Route path="content/help/categories" element={<HelpCategoriesTable />} />
        <Route path="content/help/categories/:id" element={<HelpCategoriesForm />} />

        <Route path="content/pages" element={<PagesTable />} />
        <Route path="content/pages/pages" element={<PagesTable />} />
        <Route path="content/pages/pages/:id" element={<PagesForm />} />

        <Route path="content/newsfeed" element={<NewsfeedPostsTable />} />
        <Route path="content/newsfeed/posts" element={<NewsfeedPostsTable />} />
        <Route path="content/newsfeed/posts/:id" element={<NewsfeedPostsForm />} />
        <Route path="content/newsfeed/categories" element={<NewsfeedCategoriesTable />} />
        <Route path="content/newsfeed/categories/:id" element={<NewsfeedCategoriesForm />} />

        <Route path="content/galleries" element={<GalleryGalleriesTable />} />
        <Route path="content/galleries/galleries" element={<GalleryGalleriesTable />} />
        <Route path="content/galleries/galleries/:id" element={<GalleryGalleriesForm />} />
        <Route path="content/galleries/categories" element={<GalleryCategoriesTable />} />
        <Route path="content/galleries/categories/:id" element={<GalleryCategoriesForm />} />

        <Route path="stable/horses" element={<HorsesTable />} />
        <Route path="stable/horses/:id" element={<HorsesForm />} />
        <Route path="stable/training-types" element={<TrainingTypesTable />} />
        <Route path="stable/training-types/:id" element={<TrainingTypesForm />} />
        <Route path="stable/places" element={<PlacesTable />} />
        <Route path="stable/places/:id" element={<PlacesForm />} />
        <Route path="stable/instructors" element={<InstructorsTable />} />
        <Route path="stable/instructors/:id" element={<InstructorsForm />} />
        <Route path="stable/riders" element={<RidersTable />} />
        <Route path="stable/riders/:id" element={<RidersForm />} />
        <Route path="stable/workers/" element={<WorkersTable />} />
        <Route path="stable/workers/:id" element={<WorkersForm />} />
        <Route path="stable/horses/tasks/" element={<HorseTasksTable />} />
        <Route path="stable/horses/tasks/:id" element={<HorseTasksForm />} />
        <Route path="stable/workers/tasks/" element={<GeneralTasksTable />} />
        <Route path="stable/workers/tasks/:id" element={<GeneralTasksForm />} />

        <Route path="booking/trainings" element={<TrainingsTable />} />
        <Route path="booking/trainings/:id" element={<TrainingsForm />} />
        <Route path="booking/coupons" element={<CouponsTable />} />
        <Route path="booking/coupons/:id" element={<CouponsForm />} />
        <Route path="booking/user-coupons" element={<UserCouponsTable />} />
        <Route path="booking/user-coupons/:id" element={<UserCouponsForm />} />

        <Route path="accountancy/payment-methods" element={<PaymentMethodsTable />} />
        <Route path="accountancy/payment-methods/:id" element={<PaymentMethodsForm />} />
        <Route path="accountancy/invoice-numberings" element={<InvoiceNumberingsTable />} />
        <Route path="accountancy/invoice-numberings/:id" element={<InvoiceNumberingsForm />} />
        <Route path="accountancy/currencies" element={<CurrenciesTable />} />
        <Route path="accountancy/currencies/:id" element={<CurrenciesForm />} />
        <Route path="accountancy/sellers" element={<SellersTable />} />
        <Route path="accountancy/sellers/:id" element={<SellersForm />} />
        <Route path="accountancy/buyers" element={<BuyersTable />} />
        <Route path="accountancy/buyers/:id" element={<BuyersForm />} />
        <Route path="accountancy/payment-requests" element={<PaymentRequestsTable />} />
        <Route path="accountancy/payment-requests/:id" element={<PaymentRequestsForm />} />
        <Route path="accountancy/invoices" element={<InvoicesTable />} />
        <Route path="accountancy/invoices/:id" element={<InvoicesForm />} />

        <Route index path="" element={<Dashboard />} />
      </Route>
    </Route>
  )
);
