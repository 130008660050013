import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import FormInput from 'src/components/Form/FormInput';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Horse, HorseTranslation, HorsesClient } from 'src/api/stable/Stable';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { HorseImage } from 'src/components/Stable/HorseImage';

interface HorseFilters {
  url?: string;
  title?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<HorseFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.url) f.push({ property: 'translations.url', value: form.data.url, type: '%' });
  if (form.data.title) f.push({ property: 'translations.title', value: form.data.title, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<HorseFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("url", "text", { placeholder: t('common.fields.url') })} />
        <FormInput.Default {...form.input("title", "text", { placeholder: t('common.fields.title') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const HorsesTable = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new HorsesClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Horse>>();
  const updateClaim = useClaim('HorsesUpdate');
  const deleteClaim = useClaim('HorsesDelete');
  const authGuard = useAuthGuard('/panel/', ['HorsesRead']);
  const entityTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastHorse() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Stable)}/hubs/HorsesHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: Horse) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<Horse>[] = [
    { label: '', selector: row => <HorseImage id={row.id!} className="h-8 w-8 rounded-full ring-1" />, id: "translations.name" },
    { label: t('common.fields.name'), selector: row => entityTranslation.getCurrentTranslation(row)?.name, id: "translations.name" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {updateClaim &&
          <LocalizedLink to={`/panel/stable/horses/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.horses.group", href: "/panel/stable/horses/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="">
          <h1 className="leading-1 text-2xl">{t('stable.horses.group')}</h1>
        </div>
        <div className="">
          {updateClaim &&
            <LocalizedLink to="/panel/stable/horses/create">
              <Button colorName="emerald" className="px-5 py-2">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('stable.horses.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<Horse>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
    </>
  )
}

export default HorsesTable;