import moment from "moment";
import { useEffect, useState } from "react";
import { Worker } from "src/api/stable/Stable"
import { HttpQueryFilter, GeneralTasksClient, GeneralTask } from "src/api/stable/Booking"
import { StableUserAbsence, StableUserAbsencesClient } from "src/api/stable/Stable";
import CalendarWeekView, { AvailableHours, CalendarEvent } from "src/components/Calendar/CalendarWeekView";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { IForm } from "src/hooks/useForm";
import CalendarWeekController from "src/components/Calendar/CalendarWeekController";
import useConfigurationState from "src/hooks/useConfigurationState";
import _ from "lodash";

export interface CalendarComponentProps {
  form: IForm<Worker>;
}

export default (props: CalendarComponentProps) => {
  const { form } = props;
  const [tasks, setTasks] = useState<GeneralTask[]>([]);
  const [absences, setAbsences] = useState<StableUserAbsence[]>([]);
  const apiConfiguration = useApiConfiguration();
  const tasksClient = new GeneralTasksClient(apiConfiguration);
  const absencesClient = new StableUserAbsencesClient(apiConfiguration);
  const [startCalendar, setStartCalendar] = useState(moment().startOf('isoWeek').toDate());

  useEffect(() => {
    if (!form.data?.id) return;
    absencesClient
      .get([{ property: 'userId', value: form.data.id, type: '=' } as HttpQueryFilter], [], 10, 0, undefined, undefined)
      .then(response => setAbsences(response.items ?? []));
    tasksClient
      .get([{ property: 'userId', value: form.data.id, type: '=' } as HttpQueryFilter], [], 10, 0, undefined, undefined)
      .then(response => setTasks(response.items ?? []));
  }, [form.data.id])

  const configurationState = useConfigurationState();
  const hours: AvailableHours[] = _.map(
    configurationState?.booking?.hours,
    (v, k) => ({ key: Number(k), start: [Number(v.start[0]), Number(v.start[1])], end: [Number(v.end[0]), Number(v.end[1])] })
  )
    .sort((a, b) => a.key - b.key)
    .map(v => ({ start: v.start, end: v.end }));

  const calendarEvents: CalendarEvent[] = [
    ...tasks.map(task => ({
      id: task.id!,
      start: task.start!,
      end: task.end!,
      label: task.title,
      colorName: "amber",
      active: true
    } as CalendarEvent)),
    ...absences.map(absence => ({
      id: absence.id!,
      start: absence.start!,
      end: absence.end!,
      label: "Nieobecność",
      colorName: "red",
      active: true
    } as CalendarEvent))
  ];

  return (
    <>
      <div className="cleafix">
        <div className="float-end">
          <CalendarWeekController currentDate={startCalendar} onChangeDate={setStartCalendar} />
        </div>
      </div>
      <CalendarWeekView
        startCalendar={startCalendar}
        startHour={7}
        endHour={19}
        events={calendarEvents}
        availableHours={hours}
        hideTerms
        className="bg-white overflow-hidden w-full h-full"
        termInterval={30}
        termGranulation={30}
      />
    </>
  );
}