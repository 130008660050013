import { t } from "i18next";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { ClientHelpPagesClient, Content, ContentTranslation, HttpQueryFilter } from "src/api/cms/Cms";
import ApplicationLogo from "src/components/Branding/ApplicationLogo";
import PageLink from "src/components/Cms/PageLink";
import Spinner from "src/components/Feedback/Spinner";
import LocalizedLink from "src/components/Router/LocalizedLink";
import configuration from "src/config/config";
import config from "src/config/config";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import useUser from "src/hooks/useUser";
import CountryName from "src/components/i18n/CountryName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import useConfigurationState from "src/hooks/useConfigurationState";


interface IFooterNavigation {
  service: IFooterItem[];
  user: IFooterItem[];
  guest: IFooterItem[];
}

interface IFooterItem {
  name: string;
  id: string;
  icon?: ReactElement;
}

const footerNavigation: IFooterNavigation = {
  service: [
    { id: 'b7cdaccb-08cc-49f8-82ad-739e1f738378', name: 'privacy-policy' },
    { id: '3b9f58a4-2682-4fe3-b395-6313e8d1cf56', name: 'service-agreements' },
    { id: '135bb7ab-e24b-4cb3-92ab-0f7b1d057717', name: 'stable-agreements' },
  ],
  user: [
    { id: '/user/', name: 'users.panel.header' },
    { id: '/auth/sign-out/', name: 'auth.signOut.header' }
  ],
  guest: [
    { id: '/auth/sign-in', name: 'auth.signIn.header' },
    { id: '/auth/sign-up', name: 'auth.signUp.header' },
    { id: '/auth/reset-password', name: 'auth.resetPassword.header' }
  ]
}

const Footer = () => {
  const user = useUser();
  const { i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const apiConfiguration = useApiConfiguration();
  const state = useConfigurationState();
  const apiClient = new ClientHelpPagesClient(apiConfiguration);
  const [helpPages, setHelpPages] = useState<Content[] | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient.get([{ property: 'isVisible', value: 'true', type: '=' } as HttpQueryFilter], undefined, 6, 0, undefined, undefined)
      .then(result => setHelpPages(result.items))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return (
    <footer className="bg-gradient-to-b from-[--color-primary-900] to-zinc-900" aria-labelledby="footer-heading">
      <h5 id="footer-heading" className="sr-only">
        Footer
      </h5>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-8 sm:pt-12 lg:px-8 lg:pt-16">
        <div className="md:grid md:grid-cols-2 xl:grid-cols-6 xl:gap-8 justify-between">
          <div className="space-y-8 xl:col-span-2">
            <div className="text-[--color-primary-100] opacity-50 hover:opacity-75 active:opacity-100">
              <div className="text-left md:mt-8 text-lg">
                <div className="text-xl font-medium">{config.meta.owner}</div>
                <div>{config.meta.address?.address}</div>
                <div>{config.meta.address?.extra}</div>
                <div>{config.meta.address?.postCode} {config.meta.address?.city}</div>
                <div><CountryName countryCode={config.meta.address?.countryCode} /></div>
              </div>
            </div>
          </div>
          <div className="text-end">
            {false && config.mobileApp &&
              <div className="mx-auto my-auto text-center">
                <LocalizedLink to="/download" className="text-[--color-primary-300] hover:text-[--color-primary-200] active:text-[--color-primary-100]">
                  <QRCode value={`${window.location.origin}/${i18n.resolvedLanguage}/download`} className="mt-8 mb-4 h-24 w-24 mx-auto" bgColor="transparent" fgColor="white" level="H" />
                  <div className="text-xs m-0 p-0">{t('ui.download.header')}</div>
                </LocalizedLink>
              </div>
            }
          </div>
          <div className="mt-16 grid grid-cols-1 gap-8 col-span-2 xl:col-span-3 xl:mt-0 xl:text-end">
            <div className="md:flex md:gap-8">
              <div className="md:flex-1 mt-10 md:mt-0">
                <h6 className="text-sm font-medium leading-6 text-[--color-primary-50]">{t('users.item')}</h6>
                <ul role="list" className="mt-6 space-y-4">
                  {user && footerNavigation.user.map((item) => (
                    <li key={item.name}>
                      <LocalizedLink to={item.id} className="text-sm leading-6 text-[--color-primary-300] hover:text-white">{t(item.name)}</LocalizedLink>
                    </li>
                  ))}
                  {!user && footerNavigation.guest.map((item) => (
                    <li key={item.name}>
                      <LocalizedLink to={item.id} className="text-sm leading-6 text-[--color-primary-300] hover:text-white">{t(item.name)}</LocalizedLink>
                    </li>
                  ))}
                </ul>
              </div>
              {(helpPages && helpPages?.length > 0) && <div className="md:flex-1 mt-10 md:mt-0">
                <h6 className="text-sm font-medium leading-6 text-[--color-primary-50]"><LocalizedLink to="/h/">{t('cms.help.pages.group')}</LocalizedLink></h6>
                <ul role="list" className="mt-6 space-y-4">
                  {loading && <Spinner className="h-4" />}
                  {helpPages?.map((item) => {
                    const translation = entityTranslation.getCurrentTranslation(item);
                    return (
                      <li key={item.name}>
                        <LocalizedLink to={`/h/p/${translation?.url}`} className="text-sm leading-6 text-[--color-primary-300] hover:text-white">{translation?.title}</LocalizedLink>
                      </li>
                    );
                  })}
                </ul>
              </div>}
              <div className="md:flex-1 mt-10 md:mt-0">
                <h6 className="text-sm font-medium leading-6 text-[--color-primary-50]">{t('footer.termsAndAgreements')}</h6>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.service.map((item) => (
                    <li key={item.name}>
                      <PageLink id={item.id} className="text-sm leading-6 text-[--color-primary-300] hover:text-white" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between border-t border-white/10 pt-6 mt-8 sm:mt-10 lg:mt-12">
          <div className="flex-1">
            <div className="text-xs leading-5 text-gray-400">&copy; 2024 Redini.app. {t('footer.copyright')}.</div>
          </div>
          <div className="ml-auto mr-8 gap-5 flex pt-0.5">
            {state.social.facebook && <a href={state.social.facebook} target="_blank" rel="noopener"><FontAwesomeIcon icon={faFacebookF} className="text-gray-400 hover:text-gray-200 active:text-gray-50" /></a>}
            {state.social.instagram && <a href={state.social.instagram} target="_blank" rel="noopener"><FontAwesomeIcon icon={faInstagram} className="text-gray-400 hover:text-gray-200 active:text-gray-50" /></a>}
            {state.social.tiktok && <a href={state.social.tiktok} target="_blank" rel="noopener"><FontAwesomeIcon icon={faTiktok} className="text-gray-400 hover:text-gray-200 active:text-gray-50" /></a>}
            {state.social.twitter && <a href={state.social.twitter} target="_blank" rel="noopener"><FontAwesomeIcon icon={faXTwitter} className="text-gray-400 hover:text-gray-200 active:text-gray-50" /></a>}
          </div>
          <a href="https://redini.app/" target="_blank" rel="noopener">
            <div className="text-white text-xl opacity-50 hover:opacity-75 active:opacity-100">
              <span className="font-branding mr-2">Redini</span>
              <ApplicationLogo className="inline h-4 w-auto mb-1 mr-1 fill-white flex-1" />
              <span className="text-xs ml-3">v. {configuration.version}</span>
            </div>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;