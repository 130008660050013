import { faTag } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientNewsfeedPostsClient, Content, ContentTranslation, HttpQueryFilter, HttpQueryOrder, QueryOrderDirection } from "src/api/cms/Cms";
import Alert from "src/components/Feedback/Alert";
import Spinner from "src/components/Feedback/Spinner";
import LocalizedLink from "src/components/Router/LocalizedLink";
import SectionHeader from "src/components/Typography/SectionHeader";
import configuration, { ConfigurationApis } from "src/config/config";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import useTenant from "src/hooks/useTenant";

const NewsfeedIndexWidget = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState<Content[] | undefined>();
  const [error, setError] = useState(false);
  const [pending, setPending] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientNewsfeedPostsClient(apiConfiguration);

  useEffect(() => {
    setPending(true);
    apiClient.get(
      [{ property: 'isVisible', type: '=', value: 'true' }] as HttpQueryFilter[],
      [{ property: 'created', direction: QueryOrderDirection.DESC }] as HttpQueryOrder[],
      3,
      undefined,
      undefined,
      undefined
    ).catch(() => setError(true))
      .then(response => setPosts(response?.items))
      .finally(() => setPending(false));
  }, []);

  return (
    <div className="pb-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <SectionHeader>
            {t('plans.features.newsfeed')}
          </SectionHeader>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-7xl">
          {pending && <Spinner className="size-8 mx-auto" />}
          {error && <Alert.Error title="Error" noClose />}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts?.map(post => <PostThumbnail key={post.id} post={post} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

interface PostThumbnailProps {
  post: Content;
}

const PostThumbnail = (props: PostThumbnailProps) => {
  const { post } = props;
  const { i18n, t } = useTranslation();
  const tenant = useTenant();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(post);
  const plainText = translation?.content?.replace(/<[^>]+>/g, '').substring(0, 1024) ?? "";

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientNewsfeedPostsClient(apiConfiguration);

  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<Content | undefined>();

  useEffect(() => {
    setLoading(true);
    apiClient.getPhotos(
      post.id!,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    )
      .then(response => setThumbnail(response.items?.find(t => t)))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const thumbnailUrl = thumbnail && `${configuration.api[ConfigurationApis.Content]}/api/v1/newsfeed/photos/${thumbnail.id}/download?XTenantId=${tenant}`;

  return (
    <LocalizedLink to={`/n/p/${translation?.url}`}>
      <div className="rounded-xl shadow-xl bg-white ring-1 ring-gray-300 p-5">
        <h3 className="text-2xl text-[--color-primary-700]">{entityTranslation.getCurrentTranslation(post)?.title}</h3>
        <div className="my-4">
          {loading && <Spinner className="h-4 mx-auto" />}
          {thumbnailUrl && !loading && <img
            src={thumbnailUrl}
            alt={thumbnail?.name || t('cms.defaultImage')}
            title={thumbnail?.name || t('cms.defaultImage')}
            className="aspect-[16/9] h-full object-cover"
          />}
        </div>
        <div className="my-4 leading-6 text-gray-600 max-h-24 overflow-ellipsis overflow-hidden justify-center">{plainText}</div>
        <div className="flex justify-between">
          {translation?.meta?.keywords && <div className="flex gap-x-4 text-xs">
            <FontAwesomeIcon icon={faTag} className="inline h-3 mb-1 mr-1" /> {translation?.meta?.keywords}
          </div>}
          <div className="flex gap-x-4 text-xs ml-auto">
            <time dateTime={post.created?.toLocaleString()} className="text-gray-500">
              {post.created?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'short', timeStyle: 'short' })}
            </time>
          </div>
        </div>
      </div>
    </LocalizedLink >
  )
}

export default NewsfeedIndexWidget;