import { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Instructor } from 'src/api/stable/Stable';
import { GeneralTask, GeneralTasksClient, HttpQueryFilter } from 'src/api/stable/Booking';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { IForm } from 'src/hooks/useForm';
import Avatar from 'src/components/User/Avatar';

export interface TasksComponentProps {
  form: IForm<Instructor>;
}

export default (props: TasksComponentProps) => {
  const { form } = props;
  const { t, i18n } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new GeneralTasksClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<GeneralTask>>();
  const updateClaim = useClaim('TasksUpdate');
  const deleteClaim = useClaim('TasksDelete');
  const authGuard = useAuthGuard('/panel/', ['TasksRead']);
  const applicationDispatch = useApplicationDispatch();

  const filters = [{ property: 'UserId', value: form.data.id } as HttpQueryFilter];

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastGeneralTask() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Booking)}/hubs/TasksHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: GeneralTask) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<GeneralTask>[] = [
    { label: '', selector: row => <Avatar userId={row.user?.userId} className="h-8 w-8 rounded-full ring-1" />, id: "user.userId" },
    { label: t('stable.workers.item'), selector: row => row.user?.user?.fullName, id: "user.user.givenName" },
    { label: t('common.fields.title'), selector: row => row.title, id: "title" },
    { label: t('stable.trainings.fields.start'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "start" },
    { label: t('stable.trainings.fields.end'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "end" },
    { label: t('stable.trainingTypes.fields.duration'), selector: row => Intl.NumberFormat(i18n.resolvedLanguage, { style: "unit", unit: "minute" }).format(moment.duration(moment(row.end).diff(moment(row.start))).asMinutes()), id: "id" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {updateClaim &&
          <LocalizedLink to={row.discriminator === 'HorseTask' ? `/panel/stable/horses/tasks/${row.id}` : `/panel/stable/workers/tasks/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.tasks.group", href: "/panel/stable/workers/tasks/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <Table<GeneralTask>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
      <div className="text-end">
        {updateClaim &&
          <LocalizedLink to="/panel/stable/workers/tasks/create">
            <Button colorName="emerald" className="px-5 py-2">
              <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
              {t('stable.tasks.actions.create')}
            </Button>
          </LocalizedLink>}
      </div >
    </>
  )
}