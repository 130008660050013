import { faSave, faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, Dialog } from "@headlessui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { GeneralTask, GeneralTasksClient, StableUser } from "src/api/stable/Booking";
import { HttpQueryFilter, StableUsersClient } from "src/api/stable/Stable";
import Button from "src/components/Actions/Button";
import Toast from "src/components/Feedback/Toast";
import FormDatePicker from "src/components/Form/FormDatePicker";
import FormInput from "src/components/Form/FormInput";
import FormTextarea from "src/components/Form/FormTextarea";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useForm from "src/hooks/useForm";
import useUser from "src/hooks/useUser";

interface TaskModalProps {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
  initialStart?: Date;
  onTaskCreated?: (task: GeneralTask) => void;
  onTaskDeleted?: (id: string) => void;
  task?: GeneralTask;
}

const TaskModal = (props: TaskModalProps) => {
  const { visible, setVisible, initialStart, onTaskCreated, onTaskDeleted, task } = props;
  const user = useUser();
  const form = useForm({ start: initialStart } as GeneralTask);
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new GeneralTasksClient(apiConfiguration);
  const stableUsersClient = new StableUsersClient(apiConfiguration);
  const [stableUser, setStableUser] = useState<StableUser>();

  useEffect(() => {
    form.setData({ ...form.data, start: initialStart, end: moment(initialStart).add(1, 'h').toDate() } as GeneralTask);
  }, [initialStart])

  useEffect(() => {
    if (!task) return;
    form.setData(task);
  }, [task]);

  useEffect(() => {
    stableUsersClient.get([{ property: 'userId', value: user?.id, type: '=' }] as HttpQueryFilter[], undefined, undefined, undefined, undefined, undefined)
      .then(response => setStableUser(response.items?.[0]))
      .catch(() => setVisible(false));
  }, [user?.id]);

  const onSuccess = (task?: GeneralTask) => {
    if (task) {
      Toast.success(t("common.status.success"), t("common.form.saved"));
      if (onTaskCreated) onTaskCreated(task);
      form.setData({ title: '', notes: '', description: '' } as GeneralTask);
    }
    setVisible(false);
  }

  const handleSubmit = () => {
    if (form.data.id) {
      apiClient.update(form.data.id, { ...form.data } as GeneralTask)
        .then(onSuccess)
        .catch(form.catchAnyException)
        .finally(() => form.setPending(false));
    } else {
      apiClient.create({ ...form.data, userId: stableUser?.id } as GeneralTask)
        .then(onSuccess)
        .catch(form.catchAnyException)
        .finally(() => form.setPending(false));
    }
  };

  const handleDelete = () => {
    if (form.data.id) {
      form.setPending(true);
      apiClient.delete(form.data.id)
        .then(() => {
          setVisible(false);
          if (onTaskDeleted) onTaskDeleted(form.data.id!);
        })
        .finally(() => {
          form.setData({ title: '', description: '', notes: '' } as GeneralTask);
          form.setPending(false)
        });
    }
  }

  return (
    <>
      <Transition.Root show={visible} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={() => setVisible(false)}>
          <div className="flex items-center justify-center min-h-screen z-50 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="bg-white rounded-lg p-4 z-50 flex-grow max-w-xl">
                <form onSubmit={(e) => form.onSubmit(e, handleSubmit)}>
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {t('stable.tasks.actions.create')}
                  </Dialog.Title>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div>
                      <FormDatePicker
                        value={form.data.start}
                        onChange={(value) => form.setData({ ...form.data, start: value } as GeneralTask)}
                        placeholder={t('common.fields.start')}
                        time={15}
                        min={(new Date()).toISOString()}
                        required
                      />
                    </div>
                    <div>
                      <FormDatePicker
                        value={form.data.end}
                        onChange={(value) => form.setData({ ...form.data, end: value } as GeneralTask)}
                        placeholder={t('common.fields.end')}
                        time={15}
                        min={(new Date()).toISOString()}
                        required
                      />
                    </div>
                  </div>
                  <div className="gap-y-5">
                    <FormInput.Default {...form.input('title', 'text', { placeholder: t('common.fields.title'), required: true })} autoFocus tabIndex={1} />
                    <FormTextarea.Default {...form.textArea('description', 'text', { placeholder: t('common.fields.description') })} rowsCount={3} />
                    <FormTextarea.Default {...form.textArea('notes', 'text', { placeholder: t('common.fields.notes') })} rowsCount={5} />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <Button
                        type="button"
                        colorName="gray"
                        onClick={() => setVisible(false)}
                      >
                        {t('common.actions.cancel')}
                      </Button>
                    </div>
                    <div className="flex gap-x-2">
                      {form.data.id && <Button
                        type="button"
                        colorName="rose"
                        onClick={handleDelete}
                        className="px-5"
                      >
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        {t('common.actions.delete')}
                      </Button>}
                      <Button
                        colorName="primary"
                        className="px-5"
                      >
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                        {t('common.actions.save')}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition.Root >
    </>
  );
};

export default TaskModal;