import { createRef } from "react";
import { useTranslation } from "react-i18next";
import { Horse, Training, TrainingsClient, TrainingType, TrainingTypeTranslation, HttpQueryFilter } from "src/api/stable/Booking"
import Table, { TableComponentRef } from "src/components/Data/Table";
import { ITableColumn } from "src/components/Table/Table";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { IForm } from "src/hooks/useForm";
import moment from "moment";
import { TrainingTypeImage } from "src/components/Stable/TrainingTypeImage";
import { HorseImage } from "src/components/Stable/HorseImage";
import LocalizedLink from "src/components/Router/LocalizedLink";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from 'src/components/Feedback/Toast';
import useClaim from "src/hooks/useClaim";
import { faEdit, faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

export interface TrainingsComponentProps {
  form: IForm<Horse>;
}

export default (props: TrainingsComponentProps) => {
  const { form } = props;
  const { t, i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const apiConfiguration = useApiConfiguration();
  const updateClaim = useClaim('TrainingsUpdate');
  const deleteClaim = useClaim('TrainingsDelete');
  const apiClient = new TrainingsClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Training>>();
  const filters = [{ property: 'horseId', value: form.data.id, type: '=' } as HttpQueryFilter];

  const onClickDelete = (entity: Training) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<Training>[] = [
    { label: '', selector: row => <TrainingTypeImage id={row.typeId} className="h-8 w-8 rounded-full ring-1" />, id: "typeId" },
    { label: '', selector: row => <HorseImage id={row.horseId} className="h-8 w-8 rounded-full ring-1" />, id: "horseId" },
    { label: t('common.fields.name'), selector: row => entityTranslation.getCurrentTranslation(row.type)?.name, id: "type.translations.name" },
    { label: t('stable.trainings.fields.start'), selector: row => row.start?.toLocaleString(), isOrderable: true, id: "start" },
    { label: t('stable.trainings.fields.end'), selector: row => row.end?.toLocaleString(), isOrderable: true, id: "end" },
    { label: t('stable.trainingTypes.fields.duration'), selector: row => Intl.NumberFormat(i18n.resolvedLanguage, { style: "unit", unit: "minute" }).format(moment.duration(moment(row.end).diff(moment(row.start))).asMinutes()), id: "id" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {updateClaim &&
          <LocalizedLink to={`/panel/stable/horses/tasks/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  return (
    <>
      <Table<Training>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
      <div className="text-end">
        {updateClaim &&
          <LocalizedLink to="/panel/booking/trainings/create">
            <Button colorName="emerald" className="px-5 py-2">
              <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
              {t('stable.trainings.actions.create')}
            </Button>
          </LocalizedLink>}
      </div>
    </>
  );
}