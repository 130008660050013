import { createRef, useEffect, useState } from 'react';
import ExtendedInputHTMLAttributes from './ExtendedInputHTMLAttributes';
import simulateChangeEvent from './simulateChangeEvent';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { Feedback } from './FormInput';

export type FileUploadCallback = (file: File) => Promise<string>;

export interface EditorHTMLAttributes extends ExtendedInputHTMLAttributes<HTMLInputElement> {
  uploadFile?: FileUploadCallback;
  key?: string;
}

type UploadHandler = (blobInfo: BlobInfo) => Promise<string>;
interface BlobInfo {
  blob: () => Blob;
  filename: () => string;
}

const Editor = (props: EditorHTMLAttributes) => {
  const { id, key, placeholder, value, onChange, uploadFile } = props;
  const [val, setVal] = useState<string>(value ? String(value) : "");
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (val === value) return;
    simulateChangeEvent(inputRef?.current, val);
  }, [val]);

  const handleUploadFile: UploadHandler | undefined = (blobInfo: BlobInfo) => {
    if (!uploadFile) return Promise.resolve("");
    const file = new File([blobInfo.blob()], `${(new Date().getTime())}_${blobInfo.filename()}`);
    return uploadFile(file);
  }

  useEffect(() => {
    const valuePure = value ? String(value).replace(/\s/g, "").replace(/\s+/g, '') : "";
    const valPure = val.replace(/\s/g, "").replace(/\s+/g, '');
    if (valuePure !== valPure) setVal(String(value) ?? "");
  }, [value]);

  return (
    <>
      <TinyMCEEditor
        id={id}
        key={key}
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        licenseKey="gpl"
        onEditorChange={(content, _) => setVal(content)}
        value={val}
        init={{
          placeholder: placeholder,
          height: 500,
          menubar: true,
          entity_encoding: 'raw',
          verify_html: false,
          convert_urls: false,
          remove_script_host: false,
          valid_children: "+a[div|h1|h2|h3|h4|h5|h6|p|#text]",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "preview",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            [
              `
              undo redo |
              bold italic forecolor backcolor |
              alignleft aligncenter alignright alignjustify |
              removeformat
              `
            ],
          menu: {
            file: { title: 'File', items: 'restoredraft | preview | print' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
            view: { title: 'View', items: 'code revisionhistory | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
            insert: { title: 'Insert', items: 'image link media addcomment pageembed codesample inserttable | bullist numlist outdent indent | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
            table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
          },
          content_style: "body {padding: 10px; font-family:Helvetica,Arial,sans-serif; font-size:14px } .collapse {display: block !important; }",
          content_css: ["/env.css"],
          promotion: false,
          a11y_advanced_options: true,
          images_upload_handler: uploadFile ? handleUploadFile : undefined
        }}
      />
      <input ref={inputRef} id={id} name={id} value={value} placeholder={placeholder} onChange={onChange} className="hidden" />
      {<Feedback {...props} />}
    </>
  );
}

export default Editor;