import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { HttpQueryFilter, HttpQueryOrder, QueryOrderDirection, User, UsersClient } from 'src/api/access/Authority';
import ComboBox from 'src/components/Form/FormComboBox';
import { UserOptionProps } from 'src/components/Form/FormOptions';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useUser from 'src/hooks/useUser';

export interface UserSelectComponentProps {
  onChange: ((id: string | Dictionary<User | undefined>, entity?: User) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
  hideSelf?: boolean;
}

const UserSelect = (props: UserSelectComponentProps) => {
  const { onChange, value, multiple, hideSelf, ...otherProps } = props;
  const apiConfiguration = useApiConfiguration();
  const client = new UsersClient(apiConfiguration);
  const user = useUser();
  const [entities, setEntities] = useState<User[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  useEffect(() => {
    applyAutocomplete('');
  }, []);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange(newValue, entities.find(e => e.id === newValue));
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: entities.find(e => e.id === v) })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const applyAutocomplete = (query: string) => {
    const filters = query
      .split(' ')
      .filter(phrase => phrase.length > 0)
      .map(phrase => ({ type: '%', property: 'GivenName,Surname,Email', value: phrase } as HttpQueryFilter));
    if (hideSelf) {
      filters.push({ property: 'id', value: user?.id, type: '!=' } as HttpQueryFilter);
    }
    client
      .get(
        filters,
        [{ property: 'GivenName', direction: QueryOrderDirection.ASC } as HttpQueryOrder],
        10,
        0,
        undefined,
        undefined
      )
      .then(response => setEntities(response.items ?? []))
      .catch(console.error);
  };
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => applyAutocomplete(query), 250);
  };

  const options = entities?.map(r => ({ value: r.id, user: r }) as UserOptionProps);

  return (
    <ComboBox options={options} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default UserSelect;